import { config_utils } from '@/services/configs/config_utils'
import request from '@/services/axios_instance'
import CustomPhoneInput from '@/common/CustomPhoneInput.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
    name: 'APIsConfonfigs',
    mixins: [config_utils],
    components: { CustomPhoneInput },
    data: () => ({
        testing_sms_mms: false,
        testing_valid: false,
        test_contact_number: null,
        test_message: null,
        test_message_type: 'sms',
        test_file: null,
        test_response: null,
        sending_test: false,
        paths: [
            { text: 'Admin', disabled: false, router_name: 'admin-dashboard' },
            { text: 'API', disabled: false, router_name: 'admin-apis' }
        ],
        panel_items: 10,
        connects: [],
        connects_btn: false,
        twilioConfigs: {
            balance: null,
            incoming_phone_numbers: []
        },
        refreshing: false,
        statistics: []
    }),
    computed: {
        payload() {
            return {
                to: this.test_contact_number,
                message: this.test_message,
                type: this.test_message_type,
                file: this.test_message_type === 'mms' ? this.test_file : null
            }
        }
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.all()
        this.getConnects()
        this.getTwilioConfigs()
        this.getSmsMmsStats()
    },
    methods: {
        getConnects() {
            request.get(`api/configs`).then(({ data }) => {
                this.connects = data.connects_mapping
            })
        },
        getTwilioConfigs() {
            this.refreshing = true
            request
                .get(`api/twilio/configs`)
                .then(({ data }) => {
                    this.twilioConfigs = data
                })
                .finally(() => {
                    this.refreshing = false
                })
        },
        refreshConfigs() {
            this.refreshing = true
            request
                .post(`api/twilio/configs/refresh`)
                .then(({ data }) => {
                    this.twilioConfigs = data
                    this.appSnackbar('Configuration refresh!')
                })
                .finally(() => {
                    this.refreshing = false
                })
        },
        getSmsMmsStats() {
            request.get(`api/twilio/statistics`).then(({ data }) => {
                this.statistics = data
            })
        },
        save_connects() {
            // this.sendRequest('connects', { key: 'connects', value: this.connects, type: 'object' })
            this.connects_btn = true
            this.sendUpdateConfigs(
                `api/configs/update-array`, { items: this.connects, key: 'allowed_connects' },
                () => {
                    this.connects_btn = false
                }
            )
        },
        sendTestMessaging() {
            let payload = this.payload
            if (
                (!payload.message || payload.message.trim() === '') &&
                payload.type === 'sms'
            ) {
                this.appSnackbar('Message is required for SMS type', 'error')
                return
            } else if (
                (!payload.message || payload.message.trim() === '') &&
                !payload.file &&
                payload.type === 'mms'
            ) {
                this.appSnackbar(
                    'Message is required for MMS type if no attachment',
                    'error'
                )
                return
            }
            this.sending_test = true
            request
                .post(`api/twilio/test-sending`, payload)
                .then(({ data }) => {
                    this.test_response = data
                    this.getSmsMmsStats()
                })
                .finally(() => {
                    this.sending_test = false
                })
        },
        handleFileChange(file) {
            if (!file || typeof file === 'undefined') return
            if (file.size <= 1000000 * 5) {
                //filesize limit for twilio is 5mb https://www.twilio.com/docs/sms/send-messages#example-1
                let formData = new FormData()
                formData.append('file', file)
                request.post(`api2/attachments/dropzone`, formData).then(
                    ({ data }) => {
                        this.test_file = data
                    },
                    (error) => {
                        this.appSnackbar(error, 'error')
                    }
                )
            } else {
                this.appSnackbar(`${file.name} exceed 5MB filesize limit`, 'error')
            }
        }
    }
}